<template>
  <div class="d-inline-flex">
    <div
      :class="{ recording: true, recordingOn: isRecordingInProgress }"
      @click="toggleRecording()"
    >
      <v-icon color="primary">{{
        isRecordingInProgress ? 'mdi-stop' : 'mdi-microphone'
      }}</v-icon>
    </div>
  </div>
</template>

<script>
window.SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
let SpeechRecognition = window.SpeechRecognition

export default {
  props: {
    lang: {
      type: String,
      default: 'gu-IN'
    },
    noSpeechTimeout: { type: Number, default: 2000 }, //in milliseconds
    reset: { type: Boolean, default: false }
  },
  mounted() {
    if (!this.isCompatible()) {
      this.$emit('onNotCompatible')
      return
    }
    this.resetRecorder()
  },
  data() {
    return {
      audioStartTime: undefined,
      audioEndTime: undefined,
      speechRecognition: undefined,
      speechText: [''],
      onDemandStop: false,
      displayText: '',
      isRecordingInProgress: false
    }
  },
  watch: {
    reset(newValue, oldValue) {
      if (newValue === true) {
        this.resetRecorder()
      }
    }
  },

  methods: {
    resetRecorder() {
      this.speechRecognition &&
        this.isRecordingInProgress &&
        this.speechRecognition.abort()
      this.speechRecognition = undefined
      this.speechText = ['']
      this.displayText = ''
      this.isRecordingInProgress = false
      this.onDemandStop = false
      let self = this
      const sr = new SpeechRecognition()
      sr.interimResults = true
      sr.lang = this.lang

      sr.onerror = (event) => {
        console.log(`error ==>`)
        console.log(event)
        if (event.error !== 'no-speech') {
          self.stopRecording()
        }
      }

      sr.onnomatch = (event) => {
        //  console.log('nomatch')
        self.stopRecording()
      }
      sr.onend = (event) => {
        console.log(`end ===>${self.reset}:::${self.onDemandStop}`)
        self.audioEndTime = new Date()
        if (
          !self.reset &&
          !self.onDemandStop &&
          self.audioEndTime.getTime() - self.audioStartTime.getTime() <
            self.noSpeechTimeout
        ) {
          console.log('pause detected...still continue')
          self.speechRecognition.start()
          self.speechText.push('')
        } else {
          self.updateDisplayText('')
          this.isRecordingInProgress = false
          if (!self.reset) {
            console.log('ending')
            self.$emit('onEnd', self.displayText)
          } else {
            console.log('reset complete')
            this.$emit('onReady')
          }
        }
      }

      sr.onaudioend = (event) => {
        //console.log(`audioend ===>`)
        //  console.log(event)
      }

      sr.onstart = (event) => {
        console.log('start')
        self.onDemandStop = false
        self.isRecordingInProgress = true
      }

      sr.onaudiostart = (event) => {
        //console.log('audio start')
      }

      sr.onspeechstart = (event) => {
        //    console.log('speech start')
      }
      sr.onresult = (event) => {
        self.audioStartTime = new Date()
        if (event.results) {
          let tempText = event.results[0][0].transcript

          if (event.results[0].isFinal) {
            console.log(`final ==> ${tempText}`)
          } else {
            console.log(`intrim ==> ${tempText}`)
          }
          self.updateDisplayText(tempText)
        } else {
          console.log('no result')
        }
      }
      this.speechRecognition = sr
      this.$emit('onReady')
    },

    isCompatible() {
      return (
        navigator.userAgent.indexOf('Chrome') > -1 ||
        navigator.userAgent.indexOf('Edg') > -1
      )
    },
    toggleRecording() {
      if (this.isRecordingInProgress) {
        this.stopRecording()
      } else {
        this.startRecording()
      }
    },

    stopRecording() {
      setTimeout(() => {
        console.log('stop')
        this.onDemandStop = true
        this.speechRecognition.stop()
      }, 800)
    },
    startRecording() {
      this.speechText = ['']
      this.displayText = ''
      this.audioStartTime = new Date()
      this.speechRecognition.start()
      this.$emit('onStart')
    },
    updateDisplayText(text) {
      this.speechText[this.speechText.length - 1] = text
      this.displayText = this.speechText?.join(' ')
      this.$emit('onResult', this.displayText)
    }
  }
}
</script>

<style lang="scss" scoped>
.recording {
  padding: 8px;
  justify-content: center;
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #f8f9fa;
  border-radius: 100%;
  transition: background-color 0.218s, border 0.218s, box-shadow 0.218s;
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
}
.recordingOn {
  background-color: var(--v-primary-base);
  animation: pulse-animation 2s infinite;
  border: none;
}
.recordingOn > i {
  color: white !important;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}
</style>
